import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBc7PXf_Mm3rACvIt5PqNFr6FmjNqUpBak",
  authDomain: "sfz-care.firebaseapp.com",
  projectId: "sfz-care",
  storageBucket: "sfz-care.appspot.com",
  messagingSenderId: "971317593295",
  appId: "1:971317593295:web:562734f3950348f5ffa3ba"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const sendMail = httpsCallable(functions, 'sendEmail');

export {
  functions,
  sendMail
}
import { toast } from "react-hot-toast"

const showError = (err) => toast(err,{id: "1", type: "error"})

const currentHHMMSS = () => {
    var time = new Date();
    return ("0" + time.getHours()).slice(-2)   + ":" + ("0" + time.getMinutes()).slice(-2) + ":" + ("0" + time.getSeconds()).slice(-2)
}

const formatMail = (selectedWords, rankWords, player, hours) => {

    if (selectedWords === undefined || rankWords === undefined) return 'Fail...'

    
    let newHeure = currentHHMMSS()

    let mail = `
    <section>
        <table>
            <tr>
                <th style="text-align: left;">Nom</th>
                <td>${player?.nom}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Prénom</th>
                <td>${player?.prenom}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Année de naissance</th>
                <td>${player?.anneeDeNaissance}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Sexe</th>
                <td>${player?.sexe}</td>
            </tr>
        </table>

        <br />
        <br />

        <table>
            <tr>
                <th style="text-align: left;">Date de l'entrée dans le jeu</th>
                <td>${hours?.[0]}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Heure d'entrée dans le jeu</th>
                <td>${hours?.[1]}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Heure entrée dans les règles</th>
                <td>${hours?.[2]}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Heure clic sur « START »</th>
                <td>${hours?.[3]}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Heure clic sur NIVEAU 2</th>
                <td>${hours?.[4]}</td>
            </tr>
            <tr>
                <th style="text-align: left;">Heure fin de jeu</th>
                <td>${newHeure}</td>
            </tr>
        </table>

        <br />
        <br />
        `

    mail += `<table>
        <thead>
            <tr>
                <th style="text-align: left;">Mot</th>
                <!--<th style="text-align: left;">Temps</th>-->
                <th style="text-align: left;">Position</th>
                <th style="text-align: left;">Rang</th>
                <th style="text-align: left;">Type</th>
            </tr>
        </thead>
    <tbody>`

    for (let index = 0; index < selectedWords.length; index++) {
        mail += '<tr>'
        selectedWords[index]['rang'] = rankWords.indexOf(selectedWords[index]) + 1

        mail += `<td>${selectedWords[index].label}</td>`
        //mail += `<td>${selectedWords[index].time}</td>`
        mail += `<td>${selectedWords[index].position}</td>`
        mail += `<td>${selectedWords[index].rang}</td>`
        mail += `<td>${selectedWords[index].type}</td>`

        mail += '</tr>'
    }

    mail += '</tbody></table></section>'

    console.log('send :', newHeure);

    return mail
}

export {
    showError,
    formatMail,
    currentHHMMSS
}
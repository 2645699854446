import React, { useRef } from 'react'
import gsap from 'gsap'

const Input = ({placeholder, name, onChange, value, type="text"}) => {

    const inputWrapRef = useRef(null)
    const inputRef = useRef(null)
    const svgLineRef = useRef(null)

    const textRun = () => {
        gsap.timeline({defaults: {
            duration: 0.2
        }})
        .to(svgLineRef.current, {
            attr: {d: "M0 0C133 26 266 26 400 0"}
        })
        .to(svgLineRef.current, {
            attr: {d: "M0 0C133 -20 266 -20 400 0"}
        })
        .to(svgLineRef.current, {
            attr: {d: "M0 0C0 0 400 0 400 0"}
        })
    };

    const inputFocus = () => {
        inputWrapRef.current.classList.add('active');
        inputWrapRef.current.classList.add('active2');
        textRun();
        inputWrapRef.current.classList.remove('valid', 'invalid')
    }

    const inputBlur = () => {
        if (value === '') {
            inputWrapRef.current.classList.remove('active')
        }
        inputWrapRef.current.classList.remove('active2')

        if (type !== "email") return

        let rg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.]{3,9})+\.([A-Za-z]{2,4})$/;

        if (!rg.test(value) && value !== '') {
            inputWrapRef.current.classList.remove('valid')
            inputWrapRef.current.classList.add('invalid')
            inputWrapRef.current.style.transformOrigin = "center"
        } else if (rg.test(value) && value !== '') {
            inputWrapRef.current.classList.add('valid')
            inputWrapRef.current.classList.remove('invalid')
            inputWrapRef.current.style.transformOrigin = "bottom"
        }
    }

    return (
        <div className="form-wrap">
            <div className="input" ref={inputWrapRef}>
                <label htmlFor={name}>{placeholder}</label>
                <input className='input-elm' type={type} name={name} autoComplete="off" onFocus={inputFocus} onBlur={inputBlur} ref={inputRef} value={value} onChange={(e) => onChange(e)} />
            </div>
            <svg className="line">
                <path ref={svgLineRef} d={`M0 0C0 0 400 0 400 0`} />
            </svg>
        </div>
    )
}

export default Input
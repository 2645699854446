import React, { useState, createContext } from "react";
import { currentHHMMSS } from "../utils/utils";

export const playerContext = createContext({});

const PlayerProvider = ({ children }) => {
    let today = new Date()
    
    const [player, setPlayer] = useState({});
    const [currentPageName, setCurrentPageName] = useState('')
    const [hours, setHours] = useState([`${today.getFullYear()}-${today.getMonth()+1 < 10 && '0'}${today.getMonth()+1}-${today.getDate()}`, `${currentHHMMSS()}`]);

    const setPlayerData = (data) => {
        setPlayer({...data})
    }

    const setHoursData = (data) => {
      setHours((old => [...old, data]))
    }

    const setCurrentPageNameData = (data) => {
      setCurrentPageName(_ => (data))
      console.log(currentPageName);
    }

    const reset = () => {
      setPlayer({})
      setHours([`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`])
    }

    return (
      <playerContext.Provider value={{ setPlayerData, setHoursData, setCurrentPageNameData, reset, currentPageName, hours, player  }} >
        {children}
      </playerContext.Provider>
    );
  };
  
  export default PlayerProvider;
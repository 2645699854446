import Game from "./pages/Game";
import Start from "./pages/Start";
import Welcome from "./pages/Welcome";
//import useScreenOrientation from 'react-hook-screen-orientation'
//import PopUp from "./components/PopUp";
//import React, { useState } from "react";
//import { closeIcon } from "./utils/constant";
import { Toaster } from 'react-hot-toast'
import { MobileView } from 'react-device-detect';
import End from "./pages/End";
import { useContext, useEffect, useState } from "react";
import { playerContext } from "./context/PlayerContext";

const App = () => {
  //const screenOrientation = useScreenOrientation()
  //const [isScreenOrientationWar, setIsScreenOrientationWar] = useState(true)
  
  const { currentPageName } = useContext(playerContext)
  const [currenPage, setCurrenPage] = useState(null)

  useEffect(() => {
    console.log('e');
    switch (currentPageName) {
      case '':
        setCurrenPage(<Welcome />)
        break;
      case 'start':
        setCurrenPage(<Start />)
        break;
      case 'game':
        setCurrenPage(<Game />)
        break;
      case 'end':
        setCurrenPage(<End />)
        break;
      default:
        setCurrenPage(<Welcome />)
        break;
    }
  }, [currentPageName]);

  // The effect where we show an exit prompt, but only if the formState is NOT
  // unchanged. When the form is being saved, or is already modified by the user,
  // sudden page exit could be a destructive action. Our goal is to prevent that.
  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event) => {
      console.log('leave');
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handler);
    // clean it up, if the dirty state changes
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  return (
    <div className="App">

      <Toaster
        position='top-center'
        limit={1}
      />


      <MobileView>
      {/*
        (isScreenOrientationWar && screenOrientation === 'portrait-primary') &&
        <PopUp>
          <div className="close" onClick={() => setIsScreenOrientationWar(false)}>
            <img src={closeIcon} alt="cross to close" />
          </div>
          <h2>Le jeu est optimisé pour un affichage en mode paysage</h2>
        </PopUp>
      */}
      </MobileView>

      {currenPage}
    </div>
  );
}

export default App;

const Timer = ({ seconds }) => {

    return (
        <>
            <div className="timer-bar">
                <div className="timer-bar--innert" style={{ height: ((6800 - seconds) / 6800 * 100) + '%', backgroundColor: seconds > 3400 ? 'green' : seconds > 1000 ? 'orange' : 'red' }}></div>
                <div className="bars">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </div>
        </>
    )
}

export default Timer
import React, { useContext } from 'react'
import { playerContext } from '../../context/PlayerContext'
import { endIcon, logo } from '../../utils/constant'

const End = () => {

    const { setCurrentPageNameData } = useContext(playerContext)

    setTimeout(() => {
        setCurrentPageNameData('')
    }, 60000)

    return (
        <section id="end">
            <img className='illustration' src={logo.img} alt={logo.alt} />
            <h1>LIGNE D'ARRIVEE</h1>
            <img className='illustration' src={endIcon} alt="ligne d'arrivée" />
            <p>Le résultat de votre test vous sera envoyé sous 48 heures.
                La déconnexion du site sera automatique dans quelques secondes.
                Merci pour votre participation.</p>
        </section>
    )
}

export default End
import arrowIcon from '../assets/images/arrow.svg'
import closeIcon from '../assets/images/close.png'
import logoCARE from '../assets/images/Logo_Methode_CARE.png'
import endIcon from '../assets/images/end.png'

// Type 1 : Yellow
// Type 2 : Orange
// Type 3 : Green
// Type 4 : Blue

const logo = {
    alt: "CARE la méthode des 4 moteurs",
    img: logoCARE
}


const words = [
    {
        id: 0,
        label: "Agencer",
        type: 1
    },
    {
        id: 1,
        label: "Aider",
        type: 2
    },
    {
        id: 2,
        label: "Animation",
        type: 2
    },
    {
        id: 3,
        label: "Appartenir",
        type: 2
    },
    {
        id: 4,
        label: "Arranger",
        type: 1
    },
    {
        id: 5,
        label: "Bâtir",
        type: 1
    },
    {
        id: 6,
        label: "Challenge",
        type: 3
    },
    {
        id: 7,
        label: "Collectif",
        type: 2
    },
    {
        id: 8,
        label: "Communauté",
        type: 2
    },
    {
        id: 9,
        label: "Composer",
        type: 1
    },
    {
        id: 10,
        label: "Concerner",
        type: 2
    },
    {
        id: 11,
        label: "Concours",
        type: 3
    },
    {
        id: 12,
        label: "Concurrence",
        type: 3
    },
    {
        id: 13,
        label: "Constituer",
        type: 1
    },
    {
        id: 14,
        label: "Curiosité",
        type: 4
    },
    {
        id: 15,
        label: "Découvrir",
        type: 4
    },
    {
        id: 16,
        label: "Détecter",
        type: 4
    },
    {
        id: 17,
        label: "Echafauder",
        type: 1
    },
    {
        id: 18,
        label: "Edifier",
        type: 1
    },
    {
        id: 19,
        label: "Elaborer",
        type: 1
    },
    {
        id: 20,
        label: "Emulation",
        type: 3
    },
    {
        id: 21,
        label: "Entente",
        type: 2
    },
    {
        id: 22,
        label: "Entrainement",
        type: 3
    },
    {
        id: 23,
        label: "Epreuve",
        type: 3
    },
    {
        id: 24,
        label: "Equipe",
        type: 2
    },
    {
        id: 25,
        label: "Etablir",
        type: 1
    },
    {
        id: 26,
        label: "Etape",
        type: 1
    },
    {
        id: 27,
        label: "Etudier",
        type: 4
    },
    {
        id: 29,
        label: "Examiner",
        type: 4
    },
    {
        id: 30,
        label: "Experience",
        type: 3
    },
    {
        id: 31,
        label: "Exploit",
        type: 3
    },
    {
        id: 32,
        label: "Fabriquer",
        type: 1
    },
    {
        id: 33,
        label: "Familier",
        type: 2
    },
    {
        id: 34,
        label: "Féderer",
        type: 2
    },
    {
        id: 35,
        label: "Fouiller",
        type: 4
    },
    {
        id: 36,
        label: "Gagner",
        type: 3
    },
    {
        id: 37,
        label: "Groupe",
        type: 2
    },
    {
        id: 38,
        label: "Histoire",
        type: 1
    },
    {
        id: 39,
        label: "Imaginer",
        type: 4
    },
    {
        id: 40,
        label: "Inspecter",
        type: 4
    },
    {
        id: 41,
        label: "Installer",
        type: 1
    },
    {
        id: 42,
        label: "Inventer",
        type: 4
    },
    {
        id: 43,
        label: "Jeu",
        type: 3
    },
    {
        id: 44,
        label: "Lien",
        type: 2
    },
    {
        id: 45,
        label: "Lutter",
        type: 3
    },
    {
        id: 46,
        label: "Match",
        type: 3
    },
    {
        id: 47,
        label: "Monter",
        type: 1
    },
    {
        id: 48,
        label: "Observer",
        type: 4
    },
    {
        id: 49,
        label: "Obtenir",
        type: 3
    },
    {
        id: 50,
        label: "Organiser",
        type: 1
    },
    {
        id: 51,
        label: "Parcourir",
        type: 4
    },
    {
        id: 52,
        label: "Partie",
        type: 3
    },
    {
        id: 53,
        label: "Performance",
        type: 3
    },
    {
        id: 54,
        label: "Projet",
        type: 1
    },
    {
        id: 55,
        label: "Rassembler",
        type: 2
    },
    {
        id: 56,
        label: "Récompense",
        type: 3
    },
    {
        id: 57,
        label: "Relation",
        type: 2
    },
    {
        id: 58,
        label: "Repérer",
        type: 4
    },
    {
        id: 59,
        label: "Réunir",
        type: 2
    },
    {
        id: 60,
        label: "Rivalité",
        type: 3
    },
    {
        id: 61,
        label: "Scruter",
        type: 4
    },
    {
        id: 62,
        label: "Sonder",
        type: 4
    },
    {
        id: 63,
        label: "Structurer",
        type: 1
    },
    {
        id: 64,
        label: "Support",
        type: 2
    },
    {
        id: 65,
        label: "Trouver",
        type: 4
    },
    {
        id: 66,
        label: "Union",
        type: 2
    },
    {
        id: 67,
        label: "Visiter",
        type: 4
    },
    {
        id: 68,
        label: "Voyage",
        type: 4
    },
]

const startData = [
    {
        title: "Durée du jeu",
        text: "J’ai 68 secondes pour franchir les deux niveaux. Quand je clique sur <code>START</code>, je débute donc une course contre la montre !",
    },
    {
        title: "Les règles du jeu",
        text: `
        <h3>Niveau 1 :</h3>
        <p>Une liste de mots sur 4 colonnes apparait.<br />
        Des mots en tous genres divers et variés.
        </p>
        <h4>Ma mission ?</h4>
        <p>Dans cette liste, <strong>je choisis les 20 mots qui me plaisent le plus</strong>.</p>
        <h4>Comment accéder au niveau 2 ?</h4>
        <p>Quand j’ai sélectionné <strong>20 mots pile</strong> (pas 19, ni 22...), je clique sur le bouton <code>Je passe au niveau 2</code></p>
        `
    },
    {
        text: `
        <h3>Niveau 2 :</h3>
        <p>Les 20 mots sélectionnés restent à l’écran.</p>
        <h4>Ma mission ?</h4>
        <p>Je <strong>classe ces 20 mots</strong> dans l’ordre de préférence.<br />
        Le <strong>premier</strong> mot cliqué est mon <strong>préféré</strong>.<br />
        Jusqu’au vingtième.</p>`
    },
    /*
    {
        title: "À vos marques, prêts, feu, partez",
        //text: "Attention, une fois que vous aurez appuyé sur le bouton commencé, vous ne pourrait plus revenir en arrière jusqu'à la fin du jeu. 1 seconde par mot, c'est court, tenez-vous prêt.",
        text: "<b>Rappel :</b> Le jeu global dure 68 secondes. C’est très rapide.\nEn cliquant sur <strong>START</strong>, vous débutez donc une course contre la montre !",
    },
    */
]

export {
    logo,
    words,
    arrowIcon,
    startData,
    closeIcon,
    endIcon
}
import React from 'react'
import WordCard from '../../components/WordCard';
import { words } from '../../utils/constant'

const SelectWords = ({selectedWords, addWord}) => {

    return (
        <section id='selectWords'>
            <div className="wordCards">
                {
                    words.map((word, key) => <WordCard selectedWords={selectedWords} word={word} key={key} handler={() => addWord(word, key)} >{word.label}</WordCard>)
                }
            </div>
        </section>
    )
}

export default SelectWords
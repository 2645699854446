import WordCard from '../../components/WordCard'
import { words } from '../../utils/constant'

const RankWords = ({ selectedWords, wordsRank, setWordsRank }) => {

    const handler = (word) => {

        let temp = wordsRank
        let selectedWord = selectedWords.find(x => x.id === word.id)
        if (temp.includes(selectedWord)) {
            return
        }

        for (let index = 0; index < temp.length; index++) {
            if (!temp[index]) {
                if (temp.includes(selectedWord)) {
                    temp[temp.indexOf(selectedWord)] = null
                }
                temp[index] = selectedWord
                setWordsRank([...temp])
                return
            } else {
                if (word.id === temp[index].id) {
                    temp[index] = null
                    setWordsRank([...temp])
                    return
                }
            }
        }
    }

    return (
        <div id="rankWords">

            <div className="rankWords-content">
                <div className="wordCards">
                    { 
                        words?.map((word, key ) =>
                        <WordCard key={key} word={word} selectedWords={selectedWords} handler={() => handler(word)} > {wordsRank.indexOf(selectedWords.find(x => x.id === word.id)) !== -1 && <span>{wordsRank.indexOf(selectedWords.find(x => x.id === word.id))+1}</span>} {word.label}</WordCard>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default RankWords
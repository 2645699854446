import React, { useContext, useEffect, useState } from 'react'
import Button from '../../components/Button'
import RankWords from '../RankWords'
import SelectWords from '../SelectWords'
import Timer from './components/Timer'
import { currentHHMMSS, formatMail, showError } from '../../utils/utils'
import { playerContext } from '../../context/PlayerContext'
import { sendMail } from '../../utils/firebase'

const Game = () => {

  const [currentStep, setCurrentStep] = useState(0)
  const [selectedWords, setSelectedWords] = useState([])
  const [wordsRank, setWordsRank] = useState([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null])
  const { player, hours, setHoursData, setCurrentPageNameData, reset } = useContext(playerContext)
  
  // Timer Start -> https://www.linkedin.com/pulse/creating-accurate-countdown-timer-react-native-roy-selim/
  const [basis, setBasis] = useState();
  const [timer, setTimer] = useState();
  const [timerDisp, setTimerDisp] = useState(6800);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    let _intervalId;
    if (basis) {
      _intervalId = setInterval(() => {
        setTimer(new Date().valueOf());
      }, 100);
    }
    setIntervalId(_intervalId);
    return () => {
      clearInterval(_intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basis]);

  useEffect(() => {
    if (basis && timer) {
      const toDisp = Math.floor((basis - timer) / 10);
      if (timerDisp !== toDisp) {
        setTimerDisp(toDisp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if (timerDisp <= 0) {
      clearInterval(intervalId);
      sendMail({
        to: player.email || 'joris.delorme38@gmail.com',
        from: 'joris.delorme38@gmail.com',
        subject: `Vos résultats du jeu CARE ${player.prenom + " " + player.nom}`,
        html: formatMail(selectedWords, wordsRank, player, hours)
      })
        .then(() => {
          setTimeout(() => {
            reset()
          }, 6000)
          setCurrentPageNameData('end')
        })
        .catch((err) => {
          console.log(err);
          showError("Une erreur s'est produite, vous allez être redirigé vers la page d'accueil...")
          setTimeout(() => {
            reset()
            setCurrentPageNameData('')
          }, 2000)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerDisp]);

  // Timer End

  const handleNextLevel = () => {
    if (selectedWords.length !== 20) {
      showError('Il faut 20 mots PILE !')
    } else {
      let newHeure = currentHHMMSS()
      setHoursData(newHeure)
      console.log('niv 2 :', newHeure);
      setCurrentStep(1)
    }
  }

  const addWord = (currentWord, key) => {

    console.log(`position: ${(key%4)+1},${Math.trunc((key / 4) + 1)}`);

    if (selectedWords.find(x => x.id === currentWord.id)) {
      setSelectedWords((old) => old.filter(word => word.id !== currentWord.id))
    } else {
      setSelectedWords((old) => ([...old, { ...currentWord, time: (6800 - timerDisp) / 100, position: `${(key%5)+1},${Math.trunc((key / 4) + 1)}` }]))
    }
  }

  useEffect(() => {
    var t = new Date();
    t.setMilliseconds(t.getMilliseconds() + 68000);
    setBasis(t.valueOf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section id='game'>
      <Timer seconds={timerDisp} />

      {
        currentStep === 0 ? <SelectWords selectedWords={selectedWords} addWord={addWord} /> : <RankWords wordsRank={wordsRank} setWordsRank={setWordsRank} selectedWords={selectedWords} />
      }

      {currentStep === 0 && <Button className="game-btn" handler={handleNextLevel}>Niveau 2</Button>}
    </section>
  )
}

export default Game
import React from 'react'

const WordCard = ({ word, handler, selectedWords, children }) => {

  return (
    <>
      {
        selectedWords ? <div className={selectedWords.find(w => w.id === word.id) ? "wordCard active" : "wordCard"} onClick={handler}>{children}</div> : <div className="wordCard">{children}</div>
      }
    </>
  )
}

export default WordCard
import React, { useContext } from 'react'
import Button from '../../components/Button/Button'
import Section from '../../components/Section'
//import { startData } from '../../utils/constant'
//import parse from 'html-react-parser';
//import useScreenOrientation from 'react-hook-screen-orientation'
import { playerContext } from '../../context/PlayerContext'
import { currentHHMMSS } from '../../utils/utils';

const Start = () => {

    const { setHoursData, setCurrentPageNameData } = useContext(playerContext)
  //  const screenOrientation = useScreenOrientation()

    const handler = () => {
        let newHeure = currentHHMMSS()
        setHoursData(newHeure)
        setCurrentPageNameData('game')
    }

    return (
        <Section id='start'>
            <div className="rules">
                <div className="rule">
                <h1>Règles du jeu.</h1>
                <p>Tu as 2 niveaux de jeu à franchir.</p>
                <ol>
                    <li>Choisis 20 mots.</li>
                    <li>Classe les par ordre de préférence.</li>
                </ol>
                <p>Et tout ça en <span>68 secondes.</span></p>
                </div>

                {/*
                    startData.map((data, key) => (
                        <div key={key} className="rule">
                            {screenOrientation === 'portrait-primary' ? data.title && <h2>{data.title}</h2> : <h2>{data.title}</h2>}
                            <p>{parse(data.text)}</p>
                        </div>
                    ))
                    */}
            </div>
            <Button handler={() => handler()}>START</Button>
        </Section>
    )
}

export default Start
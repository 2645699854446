import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import Button from '../../components/Button/Button'
import Input from '../../components/Input'
import Section from '../../components/Section'
import { playerContext } from '../../context/PlayerContext'
import { logo } from '../../utils/constant' // +HJ230127
import { currentHHMMSS, showError } from '../../utils/utils'

const Welcome = () => {
  
  const [player, setPlayer] = useState({
    nom: "",
    prenom: "",
    email: "",
    anneeDeNaissance: "",
    sexe: ""
  })

  const { setPlayerData, setHoursData, setCurrentPageNameData } = useContext(playerContext)

  const handlePlay = () => {
    if (player.sexe && player.email && player.prenom && player.nom) {
      if ((1900 < player.anneeDeNaissance) && (player.anneeDeNaissance < 2023)) {
        let newHeure = currentHHMMSS()
        setHoursData(newHeure)
        setPlayerData(player)
        setCurrentPageNameData('start')
      } else {
        showError('Veuillez entrer une date de naissance correcte !')
      }
    } else {
      showError('Vous devez compléter tout le formulaire !')
    }
  }

  return (
    <Section id='welcome'>
      <img className='illustration' src={logo.img} alt={logo.alt} />
      <h1>Bienvenue dans l’Expérience CARE</h1>
      <form>
        <div className="rows">
          <div className="row">
          <Input name='nom' type='text' placeholder="Nom" value={player.nom} onChange={(e) => setPlayer((old) => ({ ...old, nom: e.target.value }))} />
          <Input name='prenom' type='text' placeholder="Prénom" value={player.prenom} onChange={(e) => setPlayer((old) => ({ ...old, prenom: e.target.value }))} />
          <div className="sexe" onChange={(e) => setPlayer((old) => ({ ...old, sexe: e.target.value }))}>
            <input type="radio" id="Sx_F" value="F" name='gender'></input> <label htmlFor="Sx_F">Femme</label>
            <input type="radio" id="Sx_H" value="H" name='gender'></input> <label htmlFor="Sx_H">Homme</label>
          </div>
          </div>
          <div className="row">
          <Input name='birthdate' type='number' min="1900" max="2023" step="1" value={player.anneeDeNaissance} onChange={(e) => setPlayer((old) => ({ ...old, anneeDeNaissance: e.target.value }))} placeholder="Année de naissance" />
        <Input name='email' type='email' placeholder="Mail perso" value={player.email} onChange={(e) => setPlayer((old) => ({ ...old, email: e.target.value }))} />
          </div>
        </div>
      </form>
      <p><b>Attention :</b> vous ne pourrez jouer qu’une seule fois au <strong>jeu des 4 moteurs</strong>.<br />
        Assurez-vous d’être bien installé, au calme, avant de rentrer dans le jeu.</p>
      <Button handler={handlePlay}>Je rentre dans le jeu</Button>
    </Section>
  )
}

export default Welcome
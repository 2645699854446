import React from 'react'

const Section = ({id, children}) => {
  return (
    <section id={id} className="custom-section">
        <div className={`${id}-container`}>
            {children}
        </div>
    </section>
  )
}

export default Section